import { styled } from "@mui/material";
import { breakpoint } from "../../components/styled-components/Breakpoints/breakpoints";

export const MainContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0 4vw 1vw;
  box-sizing: border-box;

  ${breakpoint(
    "xxlarge+",
    `
    padding: 0 5vw 1vw;

  `
  )}
`;

export const Row1 = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: calc(100vh - 60px);
  justify-content: center;

  ${breakpoint(
    "medium-",
    `
    flex-direction: column;
    align-items: center;
    height: auto;
  `
  )}
`;
export const Row = styled("div")`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  align-items: flex-start;
  gap: 40px;

  ${breakpoint(
    "medium-",
    `
  flex-direction: column;
  gap: 10px;
  `
  )}
`;

export const Column = styled("div")`
  display: flex;
  flex-direction: column;
  width: 50%;
  flex: 1;

  ${breakpoint(
    "medium-",
    `
    width: 100%;
  `
  )}
`;

export const FullWidthGraphic = styled("img")`
  width: 100%;
  height: auto;
  padding: 2vw 0;
`;

export const HeaderLarge = styled("span")`
  font-family: "Gotham-Bold", Helvetica;
  color: #f6f6f6;
  font-size: 4.8vw;
  text-transform: uppercase;
  white-space: nowrap;

  ${breakpoint(
    "xxxlarge+",
    `
      font-size: 4.7vw;

  `
  )}

  ${breakpoint(
    "medium-",
    `
    text-align: center;
    font-size: 26px;
  `
  )}
`;

export const HeaderMedium = styled("span")`
  font-family: "Gotham-Bold", Helvetica;
  color: #f6f6f6;
  font-size: 4vw;
  text-transform: uppercase;
  white-space: nowrap;

  ${breakpoint(
    "xxxlarge+",
    `
      font-size: 4vw;

  `
  )}

  ${breakpoint(
    "medium-",
    `
    text-align: center;
    font-size: 24px;
  `
  )}
`;

export const Subheader = styled("span")`
  font-family: Helvetica;
  font-size: 1.8vw;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;

  ${breakpoint(
    "medium-",
    `
    font-size: 12px;
    text-align: center;
    margin: 10px 0;

  `
  )}
`;

export const SectionHeader = styled("span")`
  color: #ffb310;
  font-family: Helvetica;
  font-size: 2vw;
  width: 100%;
  text-align: left;
  letter-spacing: 2px;
  margin: 2vw 0;
  text-transform: uppercase;

  ${breakpoint(
    "medium-",
    `
    font-size: 16px;
    text-align: center;
    margin: 10px 0;
  `
  )}
`;

export const SubSectionHeader = styled("span")`
  color: #ffb310;
  font-family: Helvetica;
  font-size: 2vw;
  width: 100%;
  text-align: left;
  letter-spacing: 2px;
  text-transform: uppercase;

  ${breakpoint(
    "medium-",
    `
    font-size: 16px;
    margin: 10px 0;
  `
  )}
`;

export const WhiteSubSectionHeader = styled("span")`
  color: #f6f6f6;
  font-family: Helvetica;
  font-size: 1.5vw;
  width: 100%;
  text-align: left;
  letter-spacing: 2px;
  margin: 10px 0;
  text-transform: uppercase;

  ${breakpoint(
    "medium-",
    `
    font-size: 14px;
    text-align: center;
    margin: 10px 0;
  `
  )}
`;

export const Text = styled("p")`
  font-family: Helvetica;
  color: #f6f6f6;
  font-size: 1.2vw;
  font-weight: 300;
  line-height: 1.6vw;

  ${breakpoint(
    "medium-",
    `
    font-size: 14px;
    line-height: 20px;

  `
  )}
`;

export const HeaderContainer = styled("div")`
  display: flex;
  flex-direction: column;
  justify-content: center;
  max-width: 50%;

  ${breakpoint(
    "medium-",
    `
    max-width: 100%;
    width: 100%;
  `
  )}
`;

export const Graphic1Wrapper = styled("div")`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 50%;
  max-height: 95%;

  ${breakpoint(
    "xxlarge+",
    `
    justify-content: center;
  `
  )}
`;

export const Graphic1 = styled("img")`
  max-width: 100%;
  height: 100%;
  object-fit: contain;

  ${breakpoint(
    "medium-",
    `
    margin: 10px 0 20px 0;
  `
  )}
`;

export const GraphicWrapper = styled("div")`
  display: flex;
  width: 50%;
  flex: 1;
  justify-content: flex-end;

  ${breakpoint(
    "medium-",
    `
    width: 100%;
    justify-content: center;

  `
  )}

  ${breakpoint(
    "xxxlarge+",
    `
    justify-content: flex-start;
  `
  )}
`;

export const Graphic = styled("img")`
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
`;

export const AppButtonContainer = styled("div")`
  display: flex;
  max-width: 30%;
  align-items: flex-start;
  gap: 10px;

  ${breakpoint(
    "medium-",
    `
      align-items: center;
      justify-content: center;
      max-width: 40%;
      align-self: center;
      gap: 10px;
      margin: 10px 0 20px;
  `
  )}
`;

export const AppButton = styled("img")`
  width: 100%;
  border: 1px solid #ffb310;
  border-radius: 5px;

  ${breakpoint(
    "medium-",
    `
      width: 100%;
  `
  )}
`;

export const IframeWrapper = styled("div")`
  position: relative;
  width: 100%;
  padding-top: 56.25%;

  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
`;

export const EmailSignUpWrapper = styled("div")`
  width: 100%;
`;
