import React, { useState, useEffect } from "react";
import axios from "axios";
import {
  EmailContainer,
  EmailForm,
  EmailInput,
  ErrorText,
  GetStartedButton,
  SubHeaderText,
} from "./styles";
import { emailSignUpList } from "../../utils/vars";
import useWindowDimensions from "../../utils/windowSize";

const SUBMITTED_TEXT = "Email sent!";

const postForm = async (value: string) => {
  if (value) {
    try {
      await axios.post(
        `${process.env.GATSBY_firebase_function_domain}/smtpMailers-sendMail`,
        {
          destination: value,
          bcc: [emailSignUpList],
          subject: "Robotic Imaging - Thanks for Signing Up!",
          text: `Click the link below to create an account\n\nLink: https://roboticimaging.ai/register`,
          html: `
         <div style="font-family: Arial, sans-serif; color: #333; text-align: center; max-width: 600px;  padding: 20px; border: 1px solid #ddd; border-radius: 8px; box-shadow: 0 4px 8px rgba(0,0,0,0.1);">
            <h1 style="font-family: Helvetica, Arial, sans-serif; color: black; font-weight: 400;">Robotic Imaging</h1>
            <h2 style="font-size: 16px; font-family: Helvetica, Arial, sans-serif; font-weight: 400; color: black;">Thanks for Signing Up!</h2>
            <div style="text-align: left; background-color: #f9f9f9; padding: 15px; font-family: Helvetica, Arial, sans-serif; border-radius: 8px; margin: 20px 0;">
              <p>Click the link below to create an account:</p>
              <p><a href="https://roboticimaging.ai/register" style="color: #0000E; ">Create an account</a></p>
              <p>Download our Mobile app to connect with Web and perform your own site documentation.</p>
              <a href="https://apps.apple.com/us/app/robotic-imaging/id1546776636" style="display: inline-block; margin: 10px;">
                <img src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/emails%2FApp%20Download%20Buttons%2FAPPLE.png?alt=media&token=dcda3815-61f0-491a-a032-b3c6449b8636" alt="download on app store" style="width: 150px; height: auto;" />
              </a>
              <a href="https://play.google.com/store/apps/details?id=com.roboticimaging&pcampaignid=web_share" style="display: inline-block; margin: 10px;">
                <img src="https://firebasestorage.googleapis.com/v0/b/roboticimaging---website-b4377.appspot.com/o/emails%2FApp%20Download%20Buttons%2FGOOGLE.png?alt=media&token=8f5de6a4-d647-4cf0-b44c-7e274795cdfb" alt="download on play store" style="width: 150px; height: auto;" />
              </a>
            </div>
          </div>
        `,
        }
      );
    } catch (e) {
      console.log(e);
    }
  }
};

const EmailSignUp = () => {
  const { width } = useWindowDimensions();
  const [email, setEmail] = useState("");
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [showTypingAnimation, setShowTypingAnimation] = useState(false);
  const [error, setError] = useState<string | null>(null);

  // const placeholderText =
  //   width && width > 480
  //     ? "Enter Email Address to Sign Up and Get Started"
  //     : "Enter Email Address";
  const placeholderText = "Enter Email Address";

  const TYPING_TEXT =
    width && width > 480
      ? "Thanks, we'll be in touch shortly."
      : "Thanks, we'll be in touch.";

  const isValidEmail = (email: string) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  // Function to simulate typing animation
  const startTypingAnimation = () => {
    let index = 0;
    setShowTypingAnimation(true);
    const typingInterval = setInterval(() => {
      if (index <= TYPING_TEXT.length) {
        setEmail(TYPING_TEXT.slice(0, index));
        index++;
      } else {
        clearInterval(typingInterval);
      }
    }, 100);
  };

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    if (!isValidEmail(email)) {
      setError("Please enter a valid email.");
      return;
    }
    setError(null);
    setIsSubmitted(true);
    await postForm(email);
    startTypingAnimation();
  };

  useEffect(() => {
    if (isSubmitted) {
      const timer = setTimeout(() => {
        setIsSubmitted(false);
        setShowTypingAnimation(false);
        setEmail("");
      }, 30000);

      return () => clearTimeout(timer);
    }
  }, [isSubmitted]);

  return (
    <EmailForm onSubmit={handleSubmit}>
      <EmailContainer>
        <EmailInput
          placeholder={showTypingAnimation ? email : placeholderText}
          value={email}
          onChange={(e: any) => setEmail(e.target.value)}
          autoComplete="email"
          showTypingAnimation={showTypingAnimation}
        />
        <GetStartedButton
          type="submit"
          disabled={isSubmitted}
          isSubmitted={isSubmitted}
        >
          {isSubmitted ? SUBMITTED_TEXT : "Get Started"}
        </GetStartedButton>
      </EmailContainer>
      {error && <ErrorText>{error}</ErrorText>}
      <SubHeaderText>
        Request{" "}
        <a href="\rfp" style={{ color: "#ffb310" }}>
          Proposal here
        </a>{" "}
        and get a free quote. No card required.
      </SubHeaderText>
    </EmailForm>
  );
};

export default EmailSignUp;
