import { Button, styled } from "@mui/material";
import { breakpoint } from "../../components/styled-components/Breakpoints/breakpoints";

//nav-height: 60px
//footer-height: 160px

interface LandingProps {
  width: number | null;
  height: number | null;
}

export const MainContainer = styled("div")`
  width: 100%;
  height: calc(100vh - 60px - 165px); // Adjust for header and footer
  color: #ffb310;
  padding: 0;
  margin-top: 60px; // Header height
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;

  ${breakpoint(
    "medium-",
    `
    padding: 0 3vw;
    height: calc(100svh - 60px);
  `
  )}
`;

export const Subtext = styled("span")`
  font-family: Helvetica;
  color: #f6f6f6;
  font-size: 0.8vw;
  width: 100%;
  padding-left: 5px;
  padding-top: 2vh;
`;

export const DesktopContent = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;

  ${breakpoint(
    "medium-",
    `
    display: none;
  `
  )}
`;

export const ContentWrapper = styled("div")`
  display: flex;
  width: 100%;
  height: 100%;
`;

export const LeftSide = styled("div")`
  width: 40%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 0 2vw;
`;

export const RightSide = styled("div")`
  width: 60%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  box-sizing: border-box;
`;

export const LeftContentWrapper = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 2vh;
`;

export const MottoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderText = styled("span")`
  font-family: "Gotham-Bold", Helvetica;
  color: #f6f6f6;
  font-size: 2.5vw;
  text-transform: uppercase;
  white-space: nowrap;
  width: 100%;

  ${breakpoint(
    "medium-",
    `
      font-size: 3.5vw;
    `
  )}
  ${breakpoint(
    "small-",
    `
  font-size: 5.5vw;
`
  )}
    ${breakpoint(
    "xxlarge+",
    `
  font-size: 2.5vw;
`
  )};
`;

export const DownloadArea = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 2vh;
  margin-left: 10px;
`;

export const AppButtonContainer = styled("div")`
  display: flex;
  gap: 1vw;
`;

export const ImagesContainer = styled("div")`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  gap: 1vw;
`;

export const StyledImage = styled("img")`
  max-height: 20vh;
  object-fit: contain;
`;

export const ConveyorBeltImg = styled("img")`
  width: 100%;
  height: 100%;
  object-fit: contain;
  max-width: 100%;
  max-height: calc(
    100vh - 60px - 165px - 40px
  ); // viewport height - nav - footer - padding
`;
export const MobileImg = styled("img")`
  max-width: 100%;
  object-fit: contain;
`;

export const MobileContent = styled("div")`
  display: none;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  box-sizing: border-box;
  height: 90%;
  max-width: 100%;

  ${breakpoint(
    "medium-",
    `
    display: flex;
  `
  )}
`;

export const MobileMottoContainer = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 5px;
  text-align: center;
  color: white;
`;

export const MobileGifContainer = styled("div")`
  display: flex;
  flex-shrink: 1;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;
  max-height: 75%;
  align-items: center;
  width: fit-content;
`;

export const MobileButtonContainer = styled("div")`
  display: flex;
  padding: 10px;
  gap: 2vw;
  flex-shrink: 1;

  > a > img {
    height: 5vh;
  }
`;

export const ConveyorBeltGif = styled("video")`
  width: 100%;
  height: auto;
  max-height: 70%;
  align-self: flex-end;

  ${breakpoint(
    "xxxlarge+",
    `
      max-height: 90%;
    `
  )}

  ${breakpoint(
    "medium-",
    `
      max-height: 100%;
    `
  )}
`;

export const ContactButton = styled(Button)`
  align-self: left;
  box-sizing: border-box;
  width: fit-content;
  margin: 10px 0;
  align-items: center;
  font-size: 1vw;
  text-align: center;
  border-radius: 30px;
  color: black;
  border: 1px solid #ffb310;
  font-weight: 400;
  background-color: #ffb310;
  padding: 1.5vh;

  ${breakpoint(
    "medium-",
    `
    font-size: 3vw;
  `
  )}
`;

export const SmallText = styled("span")`
  font-family: Helvetica;
  color: #595959;
  font-size: 0.8vw;
`;

export const SubHeaderContainer = styled("div")`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 20px;
`;

export const SubHeader = styled("div")`
  display: flex;
  flex-direction: column;
  gap: 0.5vw;
`;

export const EmailInput = styled("input")<{ showTypingAnimation: boolean }>`
  width: 100%;
  height: 100%;
  background-color: #2c2c2c;
  border: 1px solid #ffb310;
  border-radius: 24px;
  padding: 0 35% 0 20px;
  color: #f6f6f6;
  font-family: Helvetica;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 16px;
  outline: none;

  &::placeholder {
    color: #595959;
    opacity: 1;
  }

  ${breakpoint(
    "xlarge+",
    `
      font-size: 16px;
      padding: 0 32% 0 24px;
    `
  )}

  ${breakpoint(
    "large",
    `
      font-size: 14px;
      padding: 0 33% 0 20px;
    `
  )}

  ${breakpoint(
    "medium",
    `
      font-size: 13px;
      padding: 0 34% 0 16px;
    `
  )}

  ${breakpoint(
    "small",
    `
      font-size: 12px;
      padding: 0 35% 0 14px;
    `
  )}

  ${breakpoint(
    "xsmall",
    `
      font-size: 11px;
      padding: 0 36% 0 12px;
      border-radius: 18px;
    `
  )}
`;

export const GetStartedButton = styled("button")<{ isSubmitted: boolean }>`
  position: absolute;
  right: 4px;
  top: 50%;
  transform: translateY(-50%);
  width: 30%;
  height: calc(100% - 8px);
  border-radius: 20px;
  border: none;
  background-color: #ffb310;
  color: black;
  font-family: Helvetica;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    opacity: 0.9;
  }

  &:disabled {
    color: #ffb310;
    background-color: black;
    border: 1px solid #ffb310;
  }

  ${breakpoint(
    "xlarge+",
    `
      font-size: 14px;
      width: 28%;
    `
  )}

  ${breakpoint(
    "large",
    `
      font-size: 13px;
      width: 29%;
    `
  )}

  ${breakpoint(
    "medium",
    `
      font-size: 12px;
      width: 30%;
    `
  )}

  ${breakpoint(
    "small",
    `
      font-size: 11px;
      width: 31%;
      border-radius: 16px;
    `
  )}

  ${breakpoint(
    "xsmall",
    `
      font-size: 10px;
      width: 32%;
      border-radius: 14px;
      height: calc(100% - 6px);
    `
  )}

  ${({ isSubmitted }) =>
    isSubmitted &&
    `
    color: #ffb310;
    background-color: black;
    border: 1px solid #ffb310;
    `}
`;

export const EmailContainer = styled("div")`
  position: relative;
  width: 100%;
  height: 48px;
  display: flex;
  align-items: center;

  ${breakpoint(
    "small-",
    `
      height: 40px;
    `
  )}
  ${breakpoint(
    "xsmall",
    `
      height: 36px;
    `
  )}
`;

export const SubHeaderText = styled("div")`
  color: #595959;
  font-family: Helvetica;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  padding: 0 20px;

  ${breakpoint(
    "medium-",
    `
      font-size: 12px;
      padding: 0 14px;
    `
  )}

  ${breakpoint(
    "small-",
    `
      font-size: 7px;
      padding: 0 12px;
    `
  )}


  a {
    color: #ffb310;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const ErrorText = styled("div")`
  padding: 0 20px;
  font-family: Helvetica;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 14px;
  color: red;

  ${breakpoint(
    "medium-",
    `
      font-size: 12px;
      padding: 0 14px;
    `
  )}

  ${breakpoint(
    "small-",
    `
      font-size: 11px;
      padding: 0 12px;
    `
  )}
`;

export const EmailForm = styled("form")`
  width: 100%;
  padding: 10px 0;
  display: flex;
  flex-direction: column;
  gap: 10px;
`;
